
  import { defineComponent, ref } from 'vue';
  import Dropdown from 'primevue/dropdown';
  import * as Catalog from '../api/helpers/Catalog';
  export default defineComponent({
    name: 'TypeDate',
    components: {
      Dropdown,
    },
    props: {
      isRequired: Boolean,
      isSubmitted: Boolean,
      answerKey: String,
      label: String
    },
    setup() {
      var days : number[] = [];
      var months = [{name:'',id:0}];
      var years : number[] = [];
      var selectedDay = ref();
      var selectedMonth = ref();
      var selectedYear = ref();
      months = Catalog.find('months');
      selectedMonth.value = months[0].id;
      return { days, months, years, selectedDay, selectedMonth, selectedYear };
    },
    mounted() {
      const minDate = new Date();
      const maxDate = new Date();

      if (this.answerKey == 'birthdate'){
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        minDate.setFullYear(minDate.getFullYear() - 100);
        minDate.setMonth(0);
        minDate.setDate(1);
      }

      this.years = this.getArray(minDate.getFullYear(), maxDate.getFullYear(), 'desc');
      this.selectedYear = this.years[0];

      this.days = this.getArray(1,this.daysInMonth(this.years[0], this.months[0].id), 'asc');
      this.selectedDay = this.days[0];

      var step = JSON.parse(sessionStorage.getItem('steps') || '{}');
      if (typeof step === 'object' && this.answerKey && step[this.answerKey]) {
        const { year, month, day } = this.splitDate(step[this.answerKey]);
        this.selectedYear = year;
        this.selectedMonth = month.toString();
        this.selectedDay = day;
      }
      this.getDays();
    },
    methods: {
      setValues() {
        const date = (this.selectedYear && this.selectedMonth && this.selectedDay) ?
          this.joinDate(this.selectedYear,this.selectedMonth,this.selectedDay) : undefined;
        if(this.answerKey == 'birthdate') this.setBirthdate(date);
        this.$emit('getValue', {
          value: date,
          valid: !((!this.selectedYear || !this.selectedMonth || !this.selectedDay) && this.isRequired),
          answerKey: this.answerKey,
        });
      },
      setBirthdate(date: any){
      this.$emit("getBirthdate", {
        birthdate: date
      });
      },
      getDays() {
        this.days = this.getArray(1,this.daysInMonth(this.selectedYear, this.selectedMonth), 'asc');
        if (this.selectedDay > this.days.length)
          this.selectedDay = this.days[this.days.length - 1];
        this.setValues();
      },
      getArray(min: number, max: number, order: string) {
        if (order.toLowerCase() == 'desc')
          return Array.from({length: max-min}, (x, y) => max - y);
        else if (order.toLowerCase() == 'asc')
          return Array.from({length: max-min+1}, (x, y) => min + y);
        else
          return [];
      },
      daysInMonth(year: number, month: number) {
        return new Date(year, month, 0).getDate();
      },
      joinDate(year: number, month: number, day: number) {
        return new Date(year,month-1,day,0,0,0,0);
      },
      splitDate(date: Date) {
        date = new Date(date);
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        };
      }
    },
  });
