
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import _ from "underscore";

export default defineComponent({
  name: "TypeText",
  components: {
    InputText,
  },
  data() {
    return {
      step: sessionStorage.getItem("steps"),
      StateOfBirth: "",
      Gender: "",
    };
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    state: String,
    city: String,
    birthdate: Date,
    stateOfBirth: String,
    gender: String,
  },
  setup(props) {
    var rules;
    var placeholder = `${props.label}`;
    const f = reactive({
      name: "",
    });
    if (props.isRequired) {
      rules = {
        name: { required },
      };
    } else {
      rules = {
        name: {},
      };
    }

    const showMessage = ref(false);
    const date = ref();

    const v$ = useVuelidate(rules, f);

    return { f, v$, showMessage, date, placeholder };
  },
  mounted() {
    let step = JSON.parse(this.step || "{}");
    if (typeof step === "object" && this.answerKey) {
      this.v$.name.$model = step[this.answerKey];
    }
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
