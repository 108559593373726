import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h6 text-center" }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "radio-group text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.details), 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catalogData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "input-container",
          key: item.value
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["radio", { 'radio-select': _ctx.v$.name.$model == item.value }])
          }, [
            _createVNode(_component_RadioButton, {
              class: "radio-button",
              onChange: _ctx.setValues,
              name: "name",
              value: item.value,
              modelValue: _ctx.v$.name.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event))
            }, null, 8, ["onChange", "value", "modelValue"]),
            _createElementVNode("label", {
              class: _normalizeClass(["radio-label font-big", [
            _ctx.isRequired && _ctx.v$.name.$model == null && _ctx.isSubmitted
              ? 'text-red shake'
              : _ctx.v$.name.$model == item.value
              ? ''
              : 'text-blue',
          ]])
            }, _toDisplayString(item.label), 3)
          ], 2)
        ]))
      }), 128))
    ])
  ]))
}