import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "number",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setValues && _ctx.setValues(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
        placeholder: _ctx.answerKey == 'loan' ? 'Desde $1000 hasta $4000' : _ctx.label,
        class: _normalizeClass([{ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted }, "p-inputtext p-component"])
      }, null, 42, _hoisted_2), [
        [_vModelText, _ctx.v$.name.$model]
      ])
    ]),
    ((_ctx.v$.name.$invalid && _ctx.isSubmitted) || _ctx.v$.name.$pending.$response)
      ? (_openBlock(), _createElementBlock("small", _hoisted_3, "Campo incorrecto. Este campo es obligatorio y solo admite números."))
      : _createCommentVNode("", true)
  ]))
}