
import { defineComponent, ref } from "vue";
import * as QuestionnaireServices from "../api/helpers/Questionnaire";
import * as LeadServices from "../api/helpers/Lead";
import * as ApplicationServices from "../api/helpers/Application";
import TypeText from "../components/TypeText.vue";
import TypeNumber from "../components/TypeNumber.vue";
import TypePhone from "../components/TypePhone.vue";
import TypeSwitch from "../components/TypeSwicth.vue";
import TypeEmail from "../components/TypeEmail.vue";
import TypeRadioButton from "../components/TypeRadioButton.vue";
import TypeYesNoButton from "../components/TypeYesNoButton.vue";
import TypeDropDown from "../components/TypeDropDown.vue";
import Slider from "../components/Slider.vue";
import TypeDate from "../components/TypeDate.vue";
import Spinner from "../components/Spinner.vue";
import TypeMask from "../components/TypeMask.vue";
import TypeCheck from "../components/Spinner.vue";
import Questionnaire from "../models/Questionnaire";
import * as FacebookServices from "../api/helpers/Facebook";
import * as EmailServices from "../api/helpers/Email";
import _ from "underscore";
import Button from "primevue/button";
import { getCurrentInstance } from "vue";
import { useGtm } from "vue-gtm";
export default defineComponent({
  name: "Steps",
  components: {
    Button,
    TypeText,
    TypeNumber,
    TypePhone,
    TypeEmail,
    TypeSwitch,
    TypeRadioButton,
    TypeYesNoButton,
    TypeDropDown,
    TypeDate,
    Spinner,
    TypeCheck,
    Slider,
    TypeMask,
  },
  data() {
    return {
      currentStep: 0,
      submitted: false,
      isvalid: true,
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      addressId: sessionStorage.getItem("addressId"),
      phoneLength: process.env.VUE_APP_PHONE_LENGTH,
      name: process.env.VUE_APP_TITLE,
      maxSteps: 0,
      minStep: 0,
      answers: {},
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_COLOR,
      postalCode: "",
      state: "",
      city: "",
      birthdate: new Date(),
      stateOfBirth: "",
      gender: "",
    };
  },
  setup() {
    const urlParams = new URLSearchParams(window.location.search);
    const flow =urlParams.get("flow") || '';
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    var loading = ref(true);
    var questionnaire = ref([
      {
        required: false,
        answers: [{ id: 0, name: "", key: "", type: "", component: "" }],
      },
    ]);
    const questionnaires = ref({ Questionnaire });
    const getQuestionnaire = async () => {
      questionnaire.value = await QuestionnaireServices.find(flow).data.steps!;
    };
    return {
      questionnaire,
      questionnaires,
      getQuestionnaire,
      loading,
      toastTime,
      flow
    };
  },
  mounted() {
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Cuestionario";
    window.scrollTo(0, 0);
    let { min, max } = this.getSteps();
    this.minStep = min;
    this.maxSteps = max;
    if (
      this.currentStep > this.minStep &&
      (!this.leadId || !this.applicationId)
    )
      this.goFirst();
    this.to(this.currentStep);
    this.loading = false;
    const to = this.to;
    const router = this.$router;
    window.onpopstate = function() {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get("step");
      if (!step && Number(sessionStorage.getItem("step")) > max)
        router.push({
          path: "resultados",
          query: { SID: sessionStorage.getItem("SID") },
        });
      else {
        const next = parseInt(step || "0") || undefined;
        to(next);
      }
    };
  },
  methods: {
    getSteps() {
      this.getQuestionnaire();
      this.questionnaires.Questionnaire.prototype = QuestionnaireServices.find(this.flow).data;
      const max = Math.max(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      const min = Math.min(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      this.currentStep = Number(sessionStorage.getItem("step")) || min;

      return { min, max };
    },
    register() {
      this.submitted = true;
      this.isvalid = true;
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          this.isvalid =
            !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
          for (var y of x.answers) {
            if (!y.valid) {
              this.isvalid = false;
              break;
            }
            Object.assign(this.answers, { [y.key]: y.value });
          }
        }
      }
      if (this.isvalid) {
        this.loading = true;
        this.submitted = false;
        if (this.currentStep == this.minStep) {
          if (this.leadId) this.updateLead();
          else this.createLead();
        } else {
          this.updateLead();
          this.updateApplication();
        }
      }
    },
    setStateCity(obj: any) {
      this.state = obj.state;
      this.city = obj.city;
    },
    setBirthdate(obj: any) {
      this.birthdate = obj.birthdate;
    },
    setStateOfBirth(obj: any) {
      this.stateOfBirth = obj.stateOfBirth;
    },
    setGender(obj: any) {
      this.gender = obj.gender;
    },
    setValue(obj: any) {
      if (obj.answerKey == "postalCode" && obj.valid) {
        this.postalCode = obj.value;
      }
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          for (var y of x.answers) {
            if (y.key == obj.answerKey) {
              Object.assign(y, { value: obj.value });
              Object.assign(y, { valid: obj.valid });
              if (y.key == "ProductId" && y.value?.length) {
                this.questionnaire[7].required = true;
                Object.assign(this.questionnaire[7].answers[0], {
                  valid: false,
                });
              } else if (y.key == "ProductId" && !y.value?.length) {
                this.questionnaire[7].required = false;
                this.questionnaires.Questionnaire.prototype.steps[7].required = false;
                Object.assign(
                  this.questionnaires.Questionnaire.prototype.steps[7]
                    .answers[0],
                  {
                    valid: true,
                  }
                );
              }
              break;
            }
          }
        }
      }
    },
    createLead() {
      const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
      LeadServices.create(this.answers).then(
        (res) => {
          const {
            data: { lead, application, newEmail },
          } = res.data;
          sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
          sessionStorage.setItem("leadId", lead.id);
          sessionStorage.setItem("applicationId", application.id);
          sessionStorage.setItem(
            "steps",
            JSON.stringify(Object.assign(steps, this.answers))
          );
          this.leadId = lead.id;
          this.applicationId = application.id;
          this.to(this.currentStep + 1);
          EmailServices.email({template:'newsletterSubscription', to: steps["email"]}).then(
            (res) => {
              this.$toast.add({
                severity: "success",
                summary: "Para completar el proceso de suscripción acabamos de enviarle un mensaje a su dirección de correo electrónico.",
                life: 2*this.toastTime,
              });
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: "error",
                summary: err,
                life: this.toastTime,
              });
            }
          );

        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err.response.data.message,
            life: this.toastTime,
          });
        }
      );
    },
    updateLead() {
      if (this.leadId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        LeadServices.update(this.answers, this.leadId).then(
          (res) => {
            const newEmail = res.data.data.newEmail;
            if (_.has(this.answers, "email"))
              sessionStorage.setItem("newEmail", newEmail ? "1" : "0");

            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );

            this.to(this.currentStep + 1);
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          }
        );
      }
    },
    updateApplication() {
      if (this.applicationId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        ApplicationServices.update(this.answers, this.applicationId).then(
          () => {
            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          }
        );
      }
    },
    to(step?: number) {
      if (!step || step < this.minStep) {
        this.$router.push("../");
      } else if (step - 1 > this.maxSteps) {
        return;
      } else {
        this.currentStep = step;
        sessionStorage.setItem("step", this.currentStep.toString());
        if (this.currentStep > this.maxSteps) {
          sessionStorage.setItem("capiLeadId", "true");
          this.$router.push({ path: 'resultados', query: { SID: sessionStorage.getItem("SID") }});
        } else {
          this.loading = this.submitted = false;
          window.scrollTo(0, 0);
          if (this.currentStep == this.maxSteps)
            this.$router.push({
              path: "cuestionario",
              query: this.flow != '' ? { step: this.currentStep, SID: sessionStorage.getItem("SID"), flow: this.flow } : { step: this.currentStep, SID: sessionStorage.getItem("SID") },
            });
          else
            this.$router.push({
              path: "cuestionario",
              query: this.flow != '' ? { step: this.currentStep, flow: this.flow } : { step: this.currentStep },
            });
        }
      }
    },
    goFirst() {
      sessionStorage.removeItem("leadId");
      sessionStorage.removeItem("applicationId");
      this.leadId = null;
      this.applicationId = null;
      this.to(this.minStep);
    },
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
  },
});
