import Steps from "./Steps";


export default class Questionnaire {
  id!: number;
  name!: string;
  order!: number;
  active!:boolean;
  steps!: Array<Steps>; 
}


