export function find(flow:string) {
  if (flow=='debt')
  {
    return {
      code: "OK",
      message: "La operación se completó exitosamente.",
      data: {
        id: 0,
        name: "Wini.mx",
        order: 0,
        active: true,
        steps: [
          {
            id: 0,
            name: "Datos de contacto",
            details: "Ingresa tu número celular a 10 dígitos, tu correo electrónico y haz clic en Siguiente",
            order: 4,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 0,
                name: "Teléfono celular",
                key: "phone",
                type: "string",
                component: "phone",
              },
            ],
            filters: [],
          },
          {
            id: 1,
            name: "",
            details: "",
            order: 5,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 1,
                name: "Correo electrónico",
                key: "email",
                type: "string",
                component: "email",
              },
            ],
            filters: [],
          },
          {
            id: 2,
            name: "",
            details: "Para continuar, acepta los siguientes términos:",
            order: 6,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 2,
                name: "Acepto los términos y condiciones del servicio",
                key: "termsAndConditions",
                type: "boolean",
                component: "switch",
              },
              {
                id: 3,
                name: "Acepto la política de privacidad",
                key: "privacyNotice",
                type: "boolean",
                component: "switch",
              }
            ],
            filters: [],
          },
          {
            id: 3,
            name: "",
            details: "",
            order: 1,
            step: 2,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 5,
                name: "¿Cuál es el monto de la deuda que quieres pagar?",
                key: "debtId",
                type: "string",
                component: "radiobutton",
                catalog: "debt"
              },
            ],
            filters: [],
          },
          {
            id: 4,
            name: "",
            details: "",
            order: 1,
            step: 3,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 6,
                name: "¿A quién le debes dinero?",
                key: "lenderId",
                type: "string",
                component: "dropdown",
                catalog: "lender"
              },
            ],
            filters: [],
          }
        ],
      },
    };
  }
  else
    return {
      code: "OK",
      message: "La operación se completó exitosamente.",
      data: {
        id: 0,
        name: "Wini.mx",
        order: 0,
        active: true,
        steps: [
          {
            id: 0,
            name: "Datos de contacto",
            details: "Ingresa tu número celular a 10 dígitos, tu correo electrónico y haz clic en Siguiente",
            order: 4,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 0,
                name: "Teléfono celular",
                key: "phone",
                type: "string",
                component: "phone",
              },
            ],
            filters: [],
          },
          {
            id: 1,
            name: "",
            details: "",
            order: 5,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 1,
                name: "Correo electrónico",
                key: "email",
                type: "string",
                component: "email",
              },
            ],
            filters: [],
          },
          {
            id: 2,
            name: "",
            details: "Para continuar, acepta los siguientes términos:",
            order: 6,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 2,
                name: "",
                key: "termsAndConditions",
                type: "boolean",
                component: "switch",
              },
              {
                id: 3,
                name: "Acepto la política de privacidad",
                key: "privacyNotice",
                type: "boolean",
                component: "switch",
              },
            ],
            filters: [],
          },
          {
            id: 3,
            name: "",
            details: "",
            order: 1,
            step: 2,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 5,
                name: "¿Cuál es tu ingreso mensual?",
                key: "incomeId",
                type: "string",
                component: "radiobutton",
                catalog: "income"
              },
            ],
            filters: [],
          },
          {
            id: 4,
            name: "",
            details: "",
            order: 2,
            step: 2,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 6,
                name: "¿Puedes comprobar tus ingresos?",
                key: "checkIncome",
                type: "boolean",
                component: "yesnobutton"
              },
            ],
            filters: [],
          },
          {
            id: 5,
            name: "",
            details: "",
            order: 1,
            step: 3,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 7,
                name: "¿Cuál es tu estatus laboral actualmente?",
                key: "professionId",
                type: "string",
                component: "radiobutton",
                catalog: "profession"
              },
            ],
            filters: [],
          },
          {
            id: 6,
            name: "",
            details: "",
            order: 1,
            step: 4,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 8,
                name: "¿Tienes una tarjeta bancaria de débito a tu nombre?",
                details:'Responde "Sí", en caso de contar con una tarjeta de débito, o "No", en caso de no tener una tarjeta de débito a tu nombre. Después, haz clic en Siguiente. Puede ser de nómina, o de cualquier institución bancaria',
                key: "debit",
                type: "boolean",
                component: "yesnobutton",
              },
            ],
            filters: [],
          },
          {
            id: 7,
            name: "",
            details: "",
            order: 1,
            step: 5,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id:9,
                name: "Fecha de nacimiento",
                key: "birthdate",
                type: "date",
                component: "date",
              },
            ],
            filters: [],
          },
          {
            id: 8,
            name: "",
            details: "",
            order: 2,
            step: 5,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 10,
                name: "¿Dónde vives?",
                key: "stateId",
                type: "string",
                component: "dropdown",
                catalog: "state",
              },
            ],
            filters: [],
          }
        ],
      },
    };
}
