
import { defineComponent, reactive, ref } from "vue";
import Dropdown from "primevue/dropdown";
import * as Catalog from "../api/helpers/Catalog";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "underscore";

export default defineComponent({
  name: "TypeDropDown",
  components: {
    Dropdown,
  },
  props: {
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    catalog: String,
    answerKey: String,
    label: String,
    postalCode: String,
  },
  setup(props) {
    var loading = ref(true);
    var cityState = ref({});
    var catalogData = ref({});
    const getCatalog = async (postalCode?: any) => {
       if (props.catalog == "state") {
        await Catalog._find(
          `/state?system=${process.env.VUE_APP_SYSTEM_ID}&order=name`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      } else if (props.catalog == "sepomex") {        
        loading.value = false;
        await Catalog._find(`/sepomex?d_codigo=${postalCode}`).then((res) => {
          catalogData.value = _.map(res.data.data, (data) => {
            return { id: data.id, name: data.dAsenta };
          });
          cityState.value = {
            city: res.data.data[0].dCiudad || res.data.data[0].DMnpio,
            state: res.data.data[0].dEstado,
          };
        });
      } else if (props.catalog == "companyphone") {
        await Catalog._find(`/phonecompany?order=name`).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      } else {
        await Catalog._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=id`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      }
    };
    const f = reactive({
      name: undefined,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    const v$ = useVuelidate(rules, f);

    return { f, v$, catalogData, getCatalog, loading, cityState };
  },
  async mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    ) {
      this.getCatalog()
      if (this.answerKey == "neighborhoodId") this.getCatalog(this.postalCode);
      this.v$.name.$model = step[this.answerKey!];
    } else this.getCatalog();
    this.setValues();
  },
  methods: {
    setValues() {
      if (this.answerKey == "stateId") {
        let state = _.filter(this.catalogData, (cd: any) => {
          return cd.id == this.v$.name.$model;
        });
        state = _.map(state, (g: any) => {
          return g.name;
        });
        this.setStateOfBirth(state);
      }
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !(!this.v$.name.$model && this.isRequired),
        answerKey: this.answerKey,
      });
    },
    setStateOfBirth(state: any) {
      this.$emit("getStateOfBirth", {
        stateOfBirth: state,
      });
    },
  },
  watch: {
    postalCode(value) {
      this.getCatalog(value);
    },
    cityState(value) {
      this.$emit("stateCity", {
        state: value.state,
        city: value.city,
      });
    },
  },
});
